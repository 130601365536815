import React, { createContext, useState, useContext, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode'; // Adjust import if needed

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
    const checkTokenExpiration = (token) => {
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                const currentTime = Date.now() / 1000; // Current time in seconds
                if (decodedToken.exp < currentTime) {
                    // Token is expired
                    return null;
                }
            } catch (error) {
                // Handle token decoding error
                return null;
            }
        }
        return token;
    };

    const initialToken = checkTokenExpiration(localStorage.getItem('jwtToken'));
    const [darkMode, setDarkMode] = useState(false);
    const [userAddress, setUserAddress] = useState(null);
    const [jwtToken, setJwtToken] = useState(initialToken);

    useEffect(() => {
        if (jwtToken) {
            localStorage.setItem('jwtToken', jwtToken);
        } else {
            localStorage.removeItem('jwtToken');
        }
    }, [jwtToken]);

    const getValidJwtToken = () => {
        return checkTokenExpiration(jwtToken);
    };

    const setMode = (mode) => {
        setDarkMode(mode);
    };

    const setAddress = (address) => {
        setUserAddress(address);
    };

    const setToken = (token) => {
        setJwtToken(checkTokenExpiration(token));
    };

    const toggleDarkMode = () => {
        setMode(!darkMode);
        document.documentElement.setAttribute(
            "data-bs-theme",
            darkMode ? "light" : "dark"
        );
    };

    return (
        <UserContext.Provider value={{ userAddress, setAddress, getValidJwtToken, setToken, darkMode, setMode, toggleDarkMode }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};

export default UserContext;
