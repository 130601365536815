const API_BASE_URL = process.env.REACT_APP_DATA_PROCESSOR_SERVICE_API_HOST || 'http://localhost:8890';

const sendMetrics = async (route, code, rtt) => {
  await fetch(`${API_BASE_URL}/metrics`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      code: code,
      route: route,
      rtt: rtt,
    }),
  });
};

export const fetchStrategyList = async (jwtToken) => {
  const startTime = performance.now();
  const response = await fetch(`${API_BASE_URL}/v1/strategy`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${jwtToken}`
    }
  });
  const endTime = performance.now();
  const rtt = (endTime - startTime) / 1000;

  await sendMetrics('/v1/strategy', response.status, rtt);

  const data = await response.json();
  return { data, responseCode: response.status };
};

export const fetchStrategyByID = async (id, jwtToken) => {
  const startTime = performance.now();
  const response = await fetch(`${API_BASE_URL}/v1/strategy/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${jwtToken}`
    }
  });
  const endTime = performance.now();
  const rtt = (endTime - startTime) / 1000;

  await sendMetrics(`/v1/strategy/${id}`, response.status, rtt);

  const data = await response.json();
  return { data, responseCode: response.status };
};
