import React, { useState } from "react";
import PropTypes from "prop-types";
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import {
    discontinuousTimeScaleProviderBuilder,
    Chart,
    ChartCanvas,
    CandlestickSeries,
    lastVisibleItemBasedZoomAnchor,
    XAxis,
    YAxis,
    CrossHairCursor,
    MouseCoordinateX,
    MouseCoordinateY,
    ZoomButtons,
    withDeviceRatio,
    withSize,
} from "react-financial-charts";
import { useUser } from '../contexts/UserContext';

const axisStyles = {
    strokeStyle: "#383E55",
    strokeWidth: 2,
    tickLabelFill: "#9EAAC7",
    tickStrokeStyle: "#383E55",
    gridLinesStrokeStyle: "rgba(56, 62, 85, 0.5)"
};

const coordinateStyles = {
    fill: "#383E55",
    textFill: "#FFFFFF"
};

const zoomButtonStyles = {
    fill: "#383E55",
    fillOpacity: 0.75,
    strokeWidth: 0,
    textFill: "#9EAAC7"
};

const crossHairStyles = {
    strokeStyle: "#9EAAC7"
};

const yExtentsCalculator = ({ plotData }) => {
    let min;
    let max;
    for (const { low, high } of plotData) {
        if (min === undefined) min = low;
        if (max === undefined) max = high;
        if (low !== undefined && min > low) min = low;
        if (high !== undefined && max < high) max = high;
    }
    if (min === undefined) min = 0;
    if (max === undefined) max = 0;

    const padding = (max - min) * 0.1;
    return [min - padding, max + padding * 2];
};

const FinancialChart = ({
    id,
    initialData,
    dateTimeFormat,
    height,
    margin,
    priceDisplayFormat,
    ratio,
    width
}) => {
    const { jwtToken, darkMode } = useUser();
    const [resetCount, setResetCount] = useState(0);

    const timeDisplayFormat = timeFormat(dateTimeFormat);
    const xScaleProvider = discontinuousTimeScaleProviderBuilder().inputDateAccessor(
        (d) => d.date
    );

    const { data, xScale, xAccessor, displayXAccessor } = xScaleProvider(
        initialData
    );

    // Adjust zoom level by increasing the number of bars displayed initially
    const initialBarsToShow = 300; // Example: Increase to show more bars initially
    const min = xAccessor(data[Math.max(0, data.length - initialBarsToShow)]);
    const max = xAccessor(data[data.length - 1]);
    const xExtents = [min, max + 1];

    const gridHeight = height - margin.top - margin.bottom;
    const barChartHeight = gridHeight / 5;
    const barChartOrigin = (_, h) => [0, h - barChartHeight];

    return (
        <ChartCanvas
            height={height}
            ratio={ratio}
            width={width}
            margin={margin}
            seriesName={`Chart ${resetCount}`}
            data={data}
            xScale={xScale}
            xAccessor={xAccessor}
            displayXAccessor={displayXAccessor}
            xExtents={xExtents}
            zoomAnchor={lastVisibleItemBasedZoomAnchor}
        >
            <Chart id={2} yExtentsCalculator={yExtentsCalculator}>
                <XAxis {...axisStyles} showGridLines />
                <MouseCoordinateX
                    displayFormat={timeDisplayFormat}
                    {...coordinateStyles}
                />
                <YAxis {...axisStyles} showGridLines />
                <MouseCoordinateY
                    rectWidth={margin.right}
                    displayFormat={priceDisplayFormat}
                    {...coordinateStyles}
                />
                <CandlestickSeries
                    fill={(d) => {
                        return d.color;
                    }}
                    wickStroke={(d) => {
                        return d.color;
                    }}
                    yAccessor={(d) => ({
                        open: d.open,
                        high: d.high,
                        low: d.low,
                        close: d.close,
                        color: d.color
                    })}
                />
                <ZoomButtons
                    onReset={() => setResetCount(resetCount + 1)}
                    {...zoomButtonStyles}
                />
            </Chart>
            <CrossHairCursor {...crossHairStyles} />
        </ChartCanvas>
    );
};

FinancialChart.propTypes = {
    dateTimeFormat: PropTypes.string,
    height: PropTypes.number,
    margin: PropTypes.object,
    priceDisplayFormat: PropTypes.func,
    ratio: PropTypes.number,
    width: PropTypes.number
};

FinancialChart.defaultProps = {
    dateTimeFormat: "%d %b '%y \xa0 %H:%M",
    height: 0,
    margin: { left: 0, right: 48, top: 0, bottom: 24 },
    priceDisplayFormat: format(".2f"),
    ratio: 0,
    width: 0
};

export const PriceChart = withSize({ style: { minHeight: 600 } })(
    withDeviceRatio()(FinancialChart)
);
