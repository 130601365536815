import React, { useState, useEffect } from 'react';
import { Container, Card } from 'react-bootstrap';
import ErrorModal from '../components/ErrorModal';
import Loader from '../components/Loader';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';

const Login = () => {
    const apiUrl = process.env.REACT_APP_AUTHENTICATION_SERVICE_API_HOST || 'http://localhost:8888';
    const navigate = useNavigate();
    const [errorModalVisible, setErrorModalVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [nonce, setNonce] = useState(null);
    const [signature, setSignature] = useState(null);
    const { userAddress, setAddress, getValidJwtToken, setToken, darkMode } = useUser();
    const token = getValidJwtToken();
    const [imageLoading, setImageLoading] = useState(true);

    useEffect(() => {
        if (!token) {
            const storedToken = getValidJwtToken();
            if (storedToken) {
                setToken(storedToken);
            }
        }
    }, [token, setToken, getValidJwtToken]);

    useEffect(() => {
        if (userAddress) {
            fetchNonce();
        }
    }, [userAddress]);

    useEffect(() => {
        if (nonce) {
            signMessage();
        }
    }, [nonce]);

    const handleConnect = async () => {
        if (typeof window.ethereum === 'undefined') {
            const errorMessage = 'MetaMask is not installed. Please install MetaMask and try again.';
            console.error(errorMessage);
            setErrorMessage(errorMessage);
            setErrorModalVisible(true);
            return;
        }

        try {
            await window.ethereum.request({ method: 'eth_requestAccounts' });

            const accounts = await window.ethereum.request({
                method: 'eth_accounts',
            });
            const newUserAddress = accounts[0];
            setAddress(newUserAddress);
        } catch (error) {
            console.error('Error interacting with MetaMask:', error.message);
            setErrorMessage(error.message);
            setErrorModalVisible(true);
        }
    };

    const handleDisconnect = () => {
        setAddress(null);
        setNonce(null);
        setSignature(null);
        setToken(null);
    };

    const fetchNonce = async () => {
        try {
            const startTime = performance.now();
            const response = await fetch(`${apiUrl}/v1/account/${userAddress}/nonce`);
            const endTime = performance.now();
            const rtt = (endTime - startTime) / 1000;

            await fetch(`${apiUrl}/metrics`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    code: response.status,
                    route: '/v1/account/:address/nonce',
                    rtt: rtt,
                }),
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(`Failed to fetch nonce: ${response.status} - ${data.message}`);
            }

            setNonce(data.nonce);
        } catch (error) {
            console.error('Error fetching nonce:', error.message);
            setErrorMessage(error.message);
            setErrorModalVisible(true);
        }
    };

    const signMessage = async () => {
        try {
            if (window.ethereum && userAddress && nonce) {
                const messageToSign = `${process.env.REACT_APP_AUTHENTICATION_SERVICE_SIGN_MESSAGE || 'Signing a message for testaztechcorp.io: '}${nonce}`;

                const signedMessage = await window.ethereum.request({
                    method: 'personal_sign',
                    params: [messageToSign, userAddress],
                });

                setSignature(signedMessage);

                await sendSignatureToServer(signedMessage);
            }
        } catch (error) {
            console.error('Error signing message:', error.message);
            setErrorMessage(error.message);
            setErrorModalVisible(true);
        }
    };

    const sendSignatureToServer = async (signature) => {
        try {
            const startTime = performance.now();
            const response = await fetch(
                `${apiUrl}/v1/account/${userAddress}/verifySignature`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ signature }),
                }
            );

            const endTime = performance.now();
            const rtt = (endTime - startTime) / 1000;

            await fetch(`${apiUrl}/metrics`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    code: response.status,
                    route: '/v1/account/:address/verifySignature',
                    rtt: rtt,
                }),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(`Failure on sending signature to server: ${response.status} - ${data.message}`);
            }

            setToken(data.jwt);
            navigate('/strategylist');
        } catch (error) {
            console.error('Error sending signature to server:', error.message);
            setErrorMessage(error.message);
            setErrorModalVisible(true);
        }
    };

    const handleCloseErrorModal = () => {
        setErrorModalVisible(false);
        handleDisconnect();
    };

    return (
        <div className={darkMode ? 'dark-mode' : ''}>
            <Container className="d-flex flex-column align-items-center justify-content-center min-vh-100">
                {!token ? (
                    <div onClick={handleConnect} className="text-decoration-none" style={{ cursor: 'pointer' }}>
                        <Card style={{ width: '90%' }}>
                            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                                <Card.Title>Connect wallet</Card.Title>
                                {imageLoading && <Loader darkMode={darkMode} />}
                                <img
                                    src="/images/MetaMask_Fox.svg.png"
                                    alt="MetaMask Fox"
                                    width="50%"
                                    height="50%"
                                    style={{ display: imageLoading ? 'none' : 'block' }}
                                    onLoad={() => setImageLoading(false)}
                                    onError={() => setImageLoading(false)}
                                />
                            </Card.Body>
                        </Card>
                    </div>
                ) : (
                    <>{/* Additional content for authenticated users */}</>
                )}
            </Container>

            <ErrorModal
                show={errorModalVisible}
                handleClose={handleCloseErrorModal}
                errorMessage={errorMessage}
            />
        </div>
    );
};

export default Login;
