// components/Loader.js
import React from 'react';
import { useUser } from '../contexts/UserContext';

const Loader = () => {
    const { darkMode } = useUser();
    
    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
            color: darkMode ? '#FFFFFF' : '#383E55'
        }}>
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
};

export default Loader;
