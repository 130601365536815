// const interpolateColor = (normalizedValue, darkMode) => {
//     const darkBlue = [0, 0, darkMode ? 160 : 255];
//     const darkRed = [darkMode ? 160 : 255, 0, 0];

//     const r = Math.round(darkBlue[0] + (darkRed[0] - darkBlue[0]) * normalizedValue);
//     const g = Math.round(darkBlue[1] + (darkRed[1] - darkBlue[1]) * normalizedValue);
//     const b = Math.round(darkBlue[2] + (darkRed[2] - darkBlue[2]) * normalizedValue);

//     return `rgb(${r},${g},${b})`;
// };

const interpolateColor = (normalizedValue, darkMode) => {
    // Bright green and bright red RGB values
    const brightGreen = [0, 255, 0];  // RGB for bright green
    const brightRed = [255, 0, 0];    // RGB for bright red

    // Calculate interpolated RGB values
    const r = Math.round(brightGreen[0] + (brightRed[0] - brightGreen[0]) * normalizedValue);
    const g = Math.round(brightGreen[1] + (brightRed[1] - brightGreen[1]) * normalizedValue);
    const b = Math.round(brightGreen[2] + (brightRed[2] - brightGreen[2]) * normalizedValue);

    // Return the interpolated color in RGB format
    return `rgb(${r},${g},${b})`;
};
const normalizeData = (data) => {
    return data
        .map(item => ({
            time: item.time,
            value: parseFloat(item.value)
        }))
        .filter(item => !isNaN(item.value));
};

export const parseData = (data, darkMode, selectedAlgorithms) => {
    const activeAlgorithms = data.algorithms.filter(algorithm => selectedAlgorithms.includes(algorithm.name));

    if (activeAlgorithms.length === 0) {
        // When no algorithms are selected, return data with color based on price movement
        return data[data.ticker + "_" + data.interval + "_" + data.source].map(d => {
            const date = new Date(d.k.t);
            return {
                date: date,
                open: +d.k.o,
                high: +d.k.h,
                low: +d.k.l,
                close: +d.k.c,
                volume: +d.k.v,
                color: d.k.c > d.k.o ? '#26a69a' : '#ef5350' // Green for up, red for down
            };
        });
    }

    const nData = activeAlgorithms.flatMap(algorithm =>
        algorithm.data.map(item => ({
            time: item.time,
            value: parseFloat(item.value)
        }))
    ).filter(item => !isNaN(item.value));

    const normalizedMap = new Map();
    nData.forEach(d => {
        if (!normalizedMap.has(d.time)) {
            normalizedMap.set(d.time, []);
        }
        normalizedMap.get(d.time).push(d.value);
    });

    const getAverage = (values) => values.reduce((a, b) => a + b, 0) / values.length;

    return data[data.ticker + "_" + data.interval + "_" + data.source].map(d => {
        const date = new Date(d.k.t);
        const normalizedValues = normalizedMap.get(d.k.T);
        const normalizedValue = normalizedValues ? getAverage(normalizedValues) : null;
        const normalized = normalizedValue !== null ? normalizedValue / 100 : null;

        return {
            date: date,
            open: +d.k.o,
            high: +d.k.h,
            low: +d.k.l,
            close: +d.k.c,
            volume: +d.k.v,
            normalizedValue: normalizedValue,
            color: normalizedValue !== null ? interpolateColor(normalized, darkMode) : (d.k.c > d.k.o ? '#26a69a' : '#ef5350')
        };
    });
};
