import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';

const Header = () => {
    const navigate = useNavigate();
    const { getValidJwtToken, setToken, toggleDarkMode } = useUser();
    const token = getValidJwtToken();

    const handleLogout = () => {
        setToken(null);
        navigate('/');
    };

    return (
        <Navbar expand="lg">
            <Container>
                <Navbar.Brand as={Link} to="/">AztechCorp</Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-toggle" />
                <Navbar.Collapse id="navbar-toggle">
                    <Nav className="me-auto">
                        <NavDropdown title="User Guide" id="user-guide-dropdown">
                            <NavDropdown.Item target="_blank" as={Link} to="https://docs.aztechcorp.io/user-guide/">User Guide</NavDropdown.Item>
                        </NavDropdown>
                        {token && (
                            <NavDropdown title="Documentation" id="documentation-dropdown">
                                <NavDropdown.Header>Authentication Service</NavDropdown.Header>
                                <NavDropdown.Item target="_blank" as={Link} to="https://docs.aztechcorp.io/auth-service-software-architecture-document/">Architecture Document</NavDropdown.Item>
                                <NavDropdown.Item target="_blank" as={Link} to="https://docs.aztechcorp.io/authentication-service-technical-design/">Technical Design Document</NavDropdown.Item>
                                <NavDropdown.Item target="_blank" as={Link} to="https://aztechcorp.io/javadocs/authentication-service/">Java Docs</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Header>Data Service</NavDropdown.Header>
                                <NavDropdown.Item target="_blank" as={Link} to="https://docs.aztechcorp.io/data-service-architecture-document/">Architecture Document</NavDropdown.Item>
                                <NavDropdown.Item target="_blank" as={Link} to="https://docs.aztechcorp.io/data-service-technical-design/">Technical Design Document</NavDropdown.Item>
                                <NavDropdown.Item target="_blank" as={Link} to="https://aztechcorp.io/javadocs/data-service/">Java Docs</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Header>Data Processor Service</NavDropdown.Header>
                                <NavDropdown.Item target="_blank" as={Link} to="https://docs.aztechcorp.io/data-processor-architecture-document/">Architecture Document</NavDropdown.Item>
                                <NavDropdown.Item target="_blank" as={Link} to="https://docs.aztechcorp.io/data-processor-technical-design/">Technical Design Document</NavDropdown.Item>
                                <NavDropdown.Item target="_blank" as={Link} to="https://aztechcorp.io/javadocs/data-processor/">Java Docs</NavDropdown.Item>
                            </NavDropdown>
                        )}
                        {token ? (
                            <>
                                <Nav.Link as={Link} to="/strategylist">
                                    Strategies
                                </Nav.Link>
                                <Nav.Link onClick={handleLogout}>
                                    Logout
                                </Nav.Link>
                            </>
                        ) : (
                            <Nav.Link as={Link} to="/login">
                                Login
                            </Nav.Link>
                        )}
                    </Nav>
                    <Nav className="ms-auto">
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="toggle-check"
                                onChange={toggleDarkMode}
                                aria-label="Toggle dark mode"
                            />
                            <label className="form-check-label" htmlFor="toggle-check">
                                Dark Mode
                            </label>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
