import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { fetchStrategyList } from '../services/api';
import { useUser } from '../contexts/UserContext';
import { Container } from 'react-bootstrap';
import Loader from '../components/Loader';

const CardList = () => {
  const { getValidJwtToken, setToken, darkMode } = useUser();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const token = getValidJwtToken();

      if (!token) {
        navigate('/');
        return;
      }

      try {
        const { data, responseCode } = await fetchStrategyList(token);

        if (responseCode === 500) {
          setToken(null);
          navigate('/');
        } else {
          setItems(data);
        }
      } catch (error) {
        console.error("Error fetching strategy list:", error);
        setToken(null);
        navigate('/');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [getValidJwtToken, navigate, setToken]);

  return (
    <Container>
      <br />
      <br />
      <div className={darkMode ? 'dark-mode' : ''} style={{ minHeight: '100vh' }}>
        {loading ? (
          <Loader />
        ) : items.length > 0 ? (
          <div className="card-list row row-cols-1 row-cols-md-6 g-3">
            {items.map(item => (
              <div key={item._id} className="col">
                <div className="card h-100">
                  <div className="card-body">
                    <h5 className="card-title">{item.name}</h5>
                    <p className="card-text">{item.ticker} - {item.interval}</p>
                    <Link to={`/strategy/${item._id}`} className="btn btn-primary">View</Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center">No items found.</div>
        )}
      </div>
    </Container>
  );
};

export default CardList;
